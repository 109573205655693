<template>
    <default-layout>
        <Tabs />
        <article class="message is-info">
            <div class="message-body">
                Copy and paste the code below into the
                <strong>&lt;head&gt;</strong>
                of every page you wish to record.
                <router-link :to="{ name: 'docs' }">
                    Learn more
                </router-link>
            </div>
        </article>
        <RecordingScript :accountId="$store.getters['account/accountId']" />
    </default-layout>
</template>

<script>
import Tabs from './Tabs'
import RecordingScript from '@/components/Code/RecordingScript'

export default {
    name: 'Script',
    components: { Tabs, RecordingScript },
}
</script>
